<template>
<div>
  <van-uploader :after-read="afterRead"  capture="user"  v-model="fileList"/>
</div>
</template>

<script>
export default {
  name: "test",
  data(){
    return{
      fileList:[]
    }
  },
  methods:{
    afterRead(){
    }
  }
}
</script>

<style scoped>

</style>
